<template>
  <div>
    <!-- <this-header /> -->
    <validation-observer ref="simpleRules">
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-md-2 col-lg-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <div class="card position-relative">

              <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
                <div class="anan-tabs__nav" />
              </div>

              <div class="d-flex justify-content-between align-items-center px-2">
                <div>
                  <h3 class="font-weight-bolder">
                    {{ $t('faq') }}
                  </h3>
                </div>

                <div class="d-flex align-items-center">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData()"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData()"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <b-overlay
                :show="showOver"
                variant="transparent"
                opacity="0.99"
                blur="5px"
                rounded="sm"
              >
                <template #overlay>
                  <div class="text-center">
                    <p id="cancel-label">
                      กรุณารอสักครู่...
                    </p>
                  </div>
                </template>
                <div class="p-2">
                  <app-collapse
                    accordion
                    type="margin"
                    class="mt-2"
                  >

                    <app-collapse-item
                      v-for="(item,index) in items"
                      :key="index"
                      :title="item.question"
                      class="p-1"
                    >
                      <div class="w-100 radius bg-light p-1">
                        <span
                          v-html="item.result"
                        />

                      </div>
                    </app-collapse-item>

                  </app-collapse>
                  <br>
                  <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0"
                      @input="getData()"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                  <!--/ collapse -->

                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BPagination, BOverlay,
} from 'bootstrap-vue'
// import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import SideBar from './profile/component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      isActive: 'all',
      search_val: null,
      items: [
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
        // { title: 'คำถามที่พบบ่อย', content: 'ตอบ' },
      ],
      showOver: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.showOver = true
      try {
        const params = {
          currentPage: this.currentPage,
          perPage: this.perPage,
          search: this.search_val,

        }
        const { data: res } = await this.$http.get('/Question/getData', { params })

        this.items = res.data
        // console.log(this.items)
        this.totalRows = res.total
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
 .radius{
    border-radius: 10px;
    width: 100%;
 }
</style>
